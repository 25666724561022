// jQuery
; (function () {
  $("[data-toggle='tooltip']").tooltip({
    container: '.wrapper',
  })

  // toasts
  $('.toast').toast('show')

  // popovers
  $('[data-toggle="popover"]').popover();

  // chosen select
  $('select').chosen({
    allow_single_deselect: true,
    placeholder_text_multiple: 'Wählen Sie Optionen',
    placeholder_text_single: 'Wählen Sie eine Option',
    no_results_text: 'Nichts gefunden zu:',
  })

  // Deaktivierte Links & Buttons nicht klickbar
  $('.disabled').on('click', function (e) {
    e.preventDefault()
  })

  // htmx toasts
  function createToast(msg_html) {
    // console.log("createToast(): " + msg_html)
    $('.system-messages').replaceWith(msg_html);
    var toast = $('.toast').toast('show');
    setTimeout(function () {
      $('.toast').toast('hide')
    }, 10000
    )
  }
  htmx.on("msg_html", (event) => {
    // console.log(event)
    createToast(event.detail.value)
  })

  // htmx + functions
  htmx.on("htmx:afterSwap", (e) => {
    // re-init popover
    $('[data-toggle="popover"]').popover();

    // // Response targeting #dialog => show the modal
    // if (e.detail.target.id == "dialog") {
    //   $("#modal").modal("show")
    // }
  })

  // htmx.on("htmx:beforeSwap", (e) => {
  //   console.log("htmx:beforeSwap", e)
  //   // Empty response targeting #dialog => hide the modal
  //   if (e.detail.target.id == "dialog" && !e.detail.xhr.response) {
  //     $("#modal").modal("hide")
  //     e.detail.shouldSwap = false
  //   }
  // })

  // // Remove dialog content after hiding
  // $("#modal").on("hidden.bs.modal", () => {
  //   $("#dialog").empty()
  // })
})()
